.back-button {
    background-color: #007bff; 
    border: none; 
    color: white; 
    padding: 10px 20px;
    text-align: center; 
    text-decoration: none; 
    display: inline-block; 
    font-size: 16px; 
    margin: 10px 0; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: background-color 0.3s ease; 
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  .back-button:focus {
    outline: none;
  }