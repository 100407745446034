.menu {
  position: absolute;
  top: 10px;
  right: 10px;
}

.menu-button {
  background-color: #fff;
  color: #333;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f1f1f1;
}

.dropdown a {
  text-decoration: none;
  color: black;
  display: block;
}

.logout-button {
    color: red !important;
}

.install-button {
  position: fixed;
  top: 10px;
  left: 10px;
  
  /* Beautification */
  background-color: #28a745; /* Blue background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  border: none; /* Remove border */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.install-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}