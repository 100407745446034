.ticket-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; 
    justify-content: center;
    flex-direction: row;
  }
  
  .ticket-card {
    width: 150px; 
    height: 150px; 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff; 
    border: 1px solid #ddd;
    border-radius: 8px; 
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .ticket-card:hover {
    background-color: #e0e0e0; 
  }