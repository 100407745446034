body {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .ticket-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .ticket-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .ticket-item p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .ticket-item button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
  }
  
  .ticket-item button:hover {
    background-color: #0056b3;
  }
  
  .ticket-form {
    margin-top: 41px;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .ticket-form input {
    margin-right: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .ticket-form button {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #28a745;
    color: #fff;
    margin: 10px 0px;
  }
  
  .ticket-form button:hover {
    background-color: #218838;
  }